.talign-center {
  text-align: center;
}
.jc-space-between {
  justify-content: space-between;
}
.bg-gray {
  background-color: #f9f9f9;
}
.b-radius-5 {
  border-radius: 5px !important;
}
.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.mb-0 {
  margin-bottom: 0px;
}
.bg-color-light-green {
  background-color: #cee0d6 !important;
}
.bg-color-orange {
  background-color: #f4e8d3;
}
.image-fit-to-div {
  width: -webkit-fill-available !important;
}
.bg-color-green {
  background-color: #116530 !important;
  border-color: #116530 !important;
}

.bg-color-yellow {
  background-color: #ffb835 !important;
  border-color: #ffb835 !important;
}

.bg-color-red {
  background-color: #eb4627 !important;
  border-color: #eb4627 !important;
}

.bg-color-orange {
  background-color: #ff7809 !important;
  border-color: #ff7809 !important;
}

.bg-color-light-green {
  background-color: rgba(17, 101, 48, 0.2) !important;
}

.bg-color-light-yellow {
  background-color: rgba(255, 184, 53, 0.2) !important;
}

.bg-color-light-red {
  background-color: rgba(235, 70, 39, 0.2) !important;
}

.bg-color-light-orange {
  background-color: rgba(255, 120, 9, 0.2) !important;
}
.bg-color-price {
  background-color: #e0e8f4 !important;
}
.line-color {
  border: 1px solid #bab4c3;
}
.ml-20p {
  margin-left: 20%;
}
.mr-20p {
  margin-right: 20%;
}
.bg-gallery-color {
  background-color: #f1f1f1;
}
.cursor-pointer {
  cursor: pointer;
}
table.dashboardTable thead tr th,
.dashboardTable tbody tr td {
  white-space: nowrap;
  border: 1px solid #E5E5E5 !important;
}
.blue {
  color: #2f80ed;
}
.fblue {
  color: #2f80ed;
}
.fblack {
  color: #000000 !important;
}
.lh-22 {
  line-height: 22px;
}
.imgWidth {
  width: 72px;
}
.fIconSize-20 {
  font-size: 20px;
}
.f-16 {
  font-size: 16px !important;
}
.etamissed-btn-missed{
  border-radius: 5px 5px 0px 0px;
  background-color: #E4836D;
  color: #ffffff;
  width: -webkit-fill-available;
  padding: 5px;
  position: absolute;
  font-weight: 400;
  bottom: 0px;
  left: 0;
  right: 0;
}
.etamissed-btn-approaching{
  border-radius: 5px 5px 0px 0px;
  background-color: #EEAB3D;
  color: #ffffff;
  width: -webkit-fill-available;
  padding: 5px;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  font-weight: 400;
}
.p-rel {
  position: relative;
}
.p-abs {
  position: absolute;
}
.optionstatus-sent-1 {
  background-color: #EAC07B;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}
.optionstatus-resub {
  background-color: #E4836D;
  font-size: 14px;
  font-weight: 500;
}
.optionstatus-pending,
.optionstatus-req {
  background-color: #73AAF4;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}
.optionstatus-approved {
  background-color: #8ED698;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
.optionstatus {
  border-radius: 0px 0px 5px 5px;
  background-color: #656060;
  color: #ffffff;
  width: -webkit-fill-available;
  padding: 5px;
  top: 0;
  left: 0;
  right: 0;
}
.quantity_type {
  background-color: #73aaf4;
  color: #ffffff;
  border-radius: 0px 0px 0px 5px;
  padding: 5px;
  top: 0;
  left: 10px;
  right: 0;
  font-size: 14px;
  font-weight: 500px;
}
.approach {
  background-color: #e1a239;
}
.postate-d {
  background-color: #ffefd3;
}
.postate-f {
  background-color: #c9edd8;
}
.shipsample {
  background-color: #003049 !important;
  border-color: #003049 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
}
.shipsample-gray {
  background-color: #d1d1d1 !important;
  color: #545454 !important;
  border-color: #d1d1d1 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
}
.f-doc-color {
  color: #979797;
}
.w-89 {
  width: 89px;
}

.btn-blue {
  background-color: #d1d1d1 !important;
  border: 1px solid #d1d1d1 !important;
  color: #000000 !important;
  width: -webkit-fill-available;
  padding: 5px;
}
.shipsample-header {
  background-color: #e8e8e8;
  line-height: 50px;
  top: 0;
  right: 0;
  left: 0;
}
.rowSelected {
  background-color: #e5e5e5;
}
.disableRow {
  opacity: 0.3;
}
.tdP-0 {
  padding: 0px !important;
}
.inputSize {
  text-align: center;
  background: #f4f4f4 !important;
  border-radius: 5px !important;
  border: 1px solid #b5b5b5 !important;
  padding: 6px 0px !important;
}
.td-padding{
  padding: 5px 7px !important;
}
.btn-color-request {
  background-color: #2f80ed !important;
  border: 1px solid #2f80ed !important;
}
.btn-color-sent {
  background-color: #e1a239 !important;
  border: 1px solid #e1a239 !important;
}
.btn-color-resubmit {
  background-color: #e1a239 !important;
  border: 1px solid #e1a239 !important;
}
.close-icon-size {
  font-size: 25px;
}
.errBorder {
  border: 1px solid #e55c3d !important;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.overlay .loading {
  position: absolute;
  left: auto;
  top: 100px;
}
.errMsg {
  font-weight: 500;
  line-height: 30px;
  font-size: 22px;
}
.fs-20{
  font-size: 20px;
}
.fc-red {
  color: red;
}
.modal-lg{
  width: 950px !important;
}
.b-1{
  border: 1px solid red;
}

.rebuy {
  background-color: #979797;
  color: #ffffff;
  border-radius: 0px 0px 5px 0px;
  padding: 5px;
  top: 0;
  left: 0;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.woven {
  background-color: #979797;
  color: #ffffff;
  border-radius: 0px 0px 0px 5px;
  padding: 5px;
  top: 0;
  right: 0;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}
.comment_log{
  background-color: #f1f1f1;
  border: 1px solid #d9d9d9;
  border-radius: 5px 5px 5px 5px;
  padding: 5px;
  width: 200px;
  text-wrap: wrap;
  font-size: 14px;
  font-weight: 500;
}
.f-8{
  font-size: 8px;
}
.f-10{
  font-size: 10px;
}
.g-value{
  background-color: #8ed699;
  right: 0;
  bottom: 1px;
}
.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
  /* Add any other styling for the sticky column header */
}
.btn-w {
  width: 198px !important;
}
.label-design-w{
  width: 120px;
}
.save-btn{
  background: #FFFFFF !important;
border: 1px solid #003049 !important;
border-radius: 6px !important;
font-weight: 500 !important;
font-size: 20px !important;
line-height: 25px !important;
text-align: center !important;
color: #003049 !important;
}
.ship-btn{
  font-weight: 500 !important;
font-size: 20px !important;
line-height: 25px !important;
text-align: center !important;

color: #FFFFFF !important;
background: #116530 !important;
border-radius: 6px !important;
}
.edit-filter{
  font-weight: 500 !important;
  font-size: 18px !important;
  width:150px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #000000 !important;
  background-color: #F9F9F9 !important;
  border-radius: 6px !important;
  box-sizing: border-box !important;
  padding: 5px !important;
}
.resetcross{
  right: -2px;
  bottom: 10px;
}


@media (min-width: 992px){
.modal-lg, .modal-xl {
    max-width: 950px;
}
}

